* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Quicksand", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}
.MuiButtonBase-root {
  font-family:"Quicksand" !important;
}
/* .head-bg{background:url(../src/asset/Homepage-image.jpg) no-repeat 0 0 / cover; position:absolute; 
top:0; left:0; width:100%; height:700px; border-radius:0 0 200px 200px ;}
.head-bg:before{content:''; position:absolute; top:0; left:0; right:0; width:100%; height:100%;
bottom: 0; background:rgba(0, 0, 0, 0.7); border-radius:0 0 200px 200px;} */
.srhBox .MuiInputBase-root .MuiInputBase-input {color:#7D8383; font-size:18px; font-weight:400; line-height:20px; 
  height:26px; font-family: "Quicksand";}
.srhBox .MuiInputBase-root .MuiOutlinedInput-notchedOutline { border:0; padding:0; top:0; left:0; color:'#7D8383' ; 
font-size:22px; font-weight:600;}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;

}

::-webkit-scrollbar-thumb {
  background: #2ec4b6;
  border-radius: 10px;
}
.sticky{ background:rgba(0, 0, 0, 0.9);}

.cstm-select>div{ width:33.33%;}
.cstm-select .MuiInputBase-root { width:100%; font-size:16px !important; font-weight:600 !important; 
color:#7D8383 !important; line-height:22px !important; font-family:"Quicksand" !important;}
.cstm-select .MuiSelect-select { padding:15px; line-height: 20px; min-height: inherit !important; 
font-size:16px; font-weight:600; font-family:"Quicksand" !important;}
.cstm-select .MuiOutlinedInput-notchedOutline { border:0;}


.head-select{}

@media(max-width:599px){
  .cstm-select>div{ width:100%;}
}