/* You can remove most of the modal-related styles since they're now handled by MUI */
.chatbot-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 12px 16px;
  margin: 0;
  border-radius: 12px;
  max-width: 85%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
  word-break: break-word;
}

.user {
  background-color: #2196f3;
  color: white;
  margin-left: auto;
}

.bot {
  background-color: white;
  margin-right: auto;
}

.input-container {
  padding: 16px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.audio-recorder {
  display: flex;
  justify-content: center;
  padding: 10px;
}

/* Scrollbar styling */
.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.empty-state {
  text-align: center;
  color: #666;
  padding: 20px;
  font-size: 14px;
} 
